// Image paths are relative to ./images/

export const portfolioData = [
    {
        name: "Covid-19.fi",
        org: "Covid-19 statistics for Finland",
        image: "portfolio/covid-19.fi.png",
        url: "https://covid-19.fi",
        tags: ["projects"],
        weigth: 120
    },
    {
        name: "Abitti Security Vulnerability 2021", 
        org: "Responsible disclosure for severe security vulnerabilities found in the exam system developed by the YTL",
        url: "https://www.abitti.fi/blogi/2021/05/abitista-on-korjattu-kaksi-tietoturvahaavoittuvuutta/",
        image: "portfolio/YTL.png",
        media: [
            {
                name: "Helsingin Sanomat",
                logo: "Helsingin-Sanomat.svg",
                url: "https://www.hs.fi/kotimaa/art-2000007980520.html"
            },
            {
                name: "Tivi",
                logo: "tivi.png",
                url: "https://www.tivi.fi/uutiset/hs-lukiolaiset-loysivat-ammottavia-aukkoja-yo-kokeiden-abitti-jarjestelmasta/f07a4571-3a7f-4ebd-a399-a73e12ba3c01"
            }
        ],
        larger: true,
        tags: ["achievements"],
        weigth: 130
    },
    {
        name: "Hack4OpenGLAM Dashboard",
        org: "Data visualizer made for Creative Commons, Global Summit 2020 and 2021",
        image: "portfolio/Hack4OpenGLAM.png",
        tags: ["projects"],
        weigth: 100
    },
    {
        name: "QR-pyörä",
        org: "Public art installation",
        image: "portfolio/QR-pyörä.png",
        media: [
            {
                name: "Etelä-Saimaa",
                logo: "Etelä-Saimaa.svg",
                url: "https://www.esaimaa.fi/paikalliset/4000671"
            }
        ],
        url: "https://qrpyora.fi",
        larger: true,
        tags: ["projects"],
        weigth: 70
    },
    {
        name: "Yritys Hyvä 2019-winner", 
        org: "Winner of the nationwide entrepreneurship competition",
        image: "portfolio/YritysHyvä.png",
        tags: ["achievements"],
        weigth: 20
    },
    {
        name: "Android App Development", 
        org: "In total almost half a million downloads",
        image: "portfolio/AppDev.png",
        tags: ["achievements"],
        weigth: 80
    },
    
    {
        name: "Website for Car Repair Shop", 
        org: "Easily managed WordPress site for local car repair shop",
        image: "portfolio/sammonlahdenak.com.png",
        url: "https://sammonlahdenak.com",
        tags: ["projects"],
        weigth: 60
    },
    
    {
        name: "Matikkaeditori.fi", 
        org: "Popular online mathematics editor",
        image: "portfolio/Matikkaeditori.fi.png",
        url: "https://v3.matikkaeditori.fi",
        tags: ["projects"],
        weigth: 110
    },

    {
        name: "Testausserveri ry",
        org: "Chairman of the board 2021 - 2022 and founder of an association promoting young people's interest in IT and cybersecurity",
        image: "portfolio/testausserveri.jpeg",
        larger: true,
        url: "https://coal.testausserveri.fi",
        tags: ["projects"],
        weigth: 140
    },

    /*
    {
        name: "OpenWilma", 
        org: "Open-source libraries for interacting with Wilma APIs",
        image: "portfolio/OpenWilma.png"
    },
    */
    {
        name: "10K+ Followers on TikTok", 
        org: "Achieved popularity on TikTok while advertising my mobile apps",
        image: "portfolio/TikTok.png",
        tags: ["achievements"],
        weigth: 90
    },
    
    {
        weigth: 150,
        name: "Sustainable Marketing in India",
        org: "As part of my international studies, our group travelled to India in 2022 to present a strategy for the Indian market to tackle climate change.",
        tags: ["projects"],
        image: "portfolio/intia.jpg",
        larger: true
    },
    {
        name: "WebKorjaamo", 
        org: "Customer and work management system for repair shop businesses",
        image: "portfolio/WebKorjaamo.png",
        tags: ["projects"],
        weigth: 40
    },
    {
        name: "Gaming Club for Children", 
        org: "Me with couple other partners founded a local gaming club in 2019",
        image: "portfolio/Club.jpg",
        tags: ["projects"],
        weigth: 10
    },
    
    {
        name: "ical-ruokalista", 
        org: "Transform school lunch menus into iCalendar format",
        image: "portfolio/ruokalista.png",
        tags: ["projects"],
        weigth: 50
    },
    
    {
        name: "Green City Block", 
        org: "Erasmus+ My Town 2020 - the project was awarded the StarT scholarship",
        description: "For my Erasmus+ school project, I modelled a city block in my home city and made it greener for the future. I used SketchUp for 3D-modelling and TwinMotion for rendering a pretty looking video.\n\nThe project won the nationwide science competition in 2020.",
        image: "portfolio/GreenCityBlock.png",
        "video": "portfolio/GreenCityBlock.mp4",
        larger: true,
        tags: ["achievements", "projects"],
        weigth: 105
    },
    {
        name: "Keissi.fi", 
        org: "I used to run a free online service for publishing photo albums",
        image: "portfolio/keissifi.png",
        tags: ["projects"],
        weigth: 30
    }
];
